// import { Flex, Box } from "@chakra-ui/react";
// import {
//   Grid,
//   GridItem,
//   Image,
//   Button,
//   Heading,
//   useBreakpointValue,
// } from "@chakra-ui/react";

// export const GridPromoAfNov = () => {
//   const columns = useBreakpointValue({ base: 1, md: 2 });

//   const heightGrid = useBreakpointValue({
//     base: "250px",
//     lg: "400px",
//   });

//   const widthGrid = useBreakpointValue({
//     base: "95%",
//     lg: "95%",
//   });

//   const widthResponsiveHeading = useBreakpointValue({
//     base: "100%",
//     md: "75%",
//     lg: "50%",
//   });

//   const contentAiFranceEconomy = [
//     {
//       title: "BOSTON(BOS)",
//       price: "Începând de la 120 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Boston.jpg",
//     },
//     {
//       title: "NEW YORK (NYC)",
//       price: "Începând de la 537 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/New York.jpg",
//     },
//     {
//       title: "LOS ANGELES(LAX)",
//       price: "Începând de la 712 euro",
//       thumbnail:
//         "https://travel-time.ro/images/af-kl/AfEconomic/Los Angeles.jpg",
//     },
//     {
//       title: "DENVER(DEN)",
//       price: "Începând de la 320 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Denver.jpg",
//     },
//     {
//       title: "TORONTO(YTO)",
//       price: "Începând de la 320 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Toronto.jpg",
//     },
//     {
//       title: "PHOENIX(PHX)",
//       price: "Începând de la 320 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Phoenix.jpg",
//     },
//   ];

//   return (
//     <>
//       {/* <Center
//         className="centerText"
//         borderBottom="1px solid"
//         fontSize={textResponsive}
//         fontWeight="bold"
//         color="#0a1373"
//         textShadow="2px 2px 4px rgba(0, 0, 0)"
//         px="6"
//         mb="6"
//       >
//         Air France Destination
//       </Center> */}
//       <Heading
//         width={widthResponsiveHeading}
//         mx="auto"
//         color="#00d4ff"
//         mt="7px"
//         textAlign="center"
//         size="lg"
//         as="h1"
//         mb={4}
//       >
//         <Image
//           src="/images/af-kl/Air France-logo.png"
//           alt="images"
//           w="500px"
//           mx="auto"
//           objectFit="cover"
//         />
//       </Heading>

//       <Flex mt="10px" flexWrap="wrap" justifyContent="center">
//         <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
//           {contentAiFranceEconomy.map((image) => {
//             return (
//               <Box key={image.id} mb={6}>
//                 <GridItem position="relative" width={widthGrid} mx="auto">
//                   <Box
//                     className="description-title"
//                     mx="auto"
//                     p={2}
//                     mb={3}
//                     boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
//                     display="flex"
//                     justifyContent="center"
//                     background="#0a1373"
//                     color="white"
//                     fontWeight="bold"
//                     fontSize="1.2rem"
//                   >
//                     {image.title}
//                   </Box>
//                   <a href="https://wwws.airfrance.ro/travel-guide/destinations">
//                     <Image
//                       src={image.thumbnail}
//                       alt={image.alt}
//                       w="100%"
//                       h={heightGrid}
//                       objectFit="cover"
//                     />
//                   </a>

//                   <a href="https://wwws.airfrance.ro/travel-guide/destinations">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       Călătorește acum cu Air France!
//                     </Button>
//                   </a>
//                 </GridItem>
//               </Box>
//             );
//           })}
//         </Grid>
//       </Flex>
//     </>
//   );
// };

import { Flex, Box } from "@chakra-ui/react";
import {
  Grid,
  GridItem,
  Image,
  Button,
  Center,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";

export const GridPromoAfNov = () => {
  const columns = useBreakpointValue({ base: 1, md: 2 });

  const heightGrid = useBreakpointValue({
    base: "250px",
    lg: "400px",
  });

  const widthGrid = useBreakpointValue({
    base: "95%",
    lg: "95%",
  });

  const widthResponsiveHeading = useBreakpointValue({
    base: "100%",
    md: "75%",
    lg: "50%",
  });

  const textResponsive = useBreakpointValue({
    base: "20px",
    lg: "35px",
  });

  const contentAiFranceEconomy = [
    {
      title: "NEW YORK (NYC)",
      price: "Începând de la 528 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/New York.jpg",
    },
    {
      title: "FORT DE FRANCE (FDF)",
      price: "Începând de la 980 euro",
      thumbnail:
        "https://travel-time.ro/images/af-kl/AfEconomic/Fort-De-France.jpg",
    },
    {
      title: "MIAMI(MIA)",
      price: "Începând de la 712 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Miami.jpg",
    },
    {
      title: "BOSTON(BOS)",
      price: "Începând de la 525 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Boston.jpg",
    },
    {
      title: "HONG KONG(HKG)",
      price: "Începând de la 600 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Hong Kong.jpg",
    },
    {
      title: "BEIJING(BJS)",
      price: "Începând de la 552 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Beijing.jpg",
    },
    {
      title: "NAIROBI(NBO)",
      price: "Începând de la 765 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Nairobi.jpg",
    },
    {
      title: "PHOENIX(PHX)",
      price: "Începând de la 730 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Phoenix.jpg",
    },
  ];

  return (
    <>
      <Heading
        width={widthResponsiveHeading}
        mx="auto"
        color="#00d4ff"
        mt="7px"
        textAlign="center"
        size="lg"
        as="h1"
        mb={4}
      >
        <Image
          src="/images/af-kl/Air France-logo.png"
          alt="images"
          w="500px"
          mx="auto"
          objectFit="cover"
        />
      </Heading>

      <Center
        className="centerText"
        borderBottom="1px solid"
        fontSize={textResponsive}
        fontWeight="bold"
        color="#0a1373"
        textShadow="2px 2px 4px rgba(0, 0, 0)"
        px="6"
        mb="6"
      >
        Air France Economy Class
      </Center>

      <Flex mt="10px" flexWrap="wrap" justifyContent="center">
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
          {contentAiFranceEconomy.map((image) => {
            return (
              <Box key={image.id} mb={6}>
                <GridItem position="relative" width={widthGrid} mx="auto">
                  <Box
                    className="description-title"
                    mx="auto"
                    p={2}
                    mb={3}
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    display="flex"
                    justifyContent="center"
                    background="#0a1373"
                    color="white"
                    fontWeight="bold"
                    fontSize="1.2rem"
                  >
                    {image.title}
                  </Box>
                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Image
                      src={image.thumbnail}
                      alt={image.alt}
                      w="100%"
                      h={heightGrid}
                      objectFit="cover"
                    />
                  </a>

                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Button
                      position="absolute"
                      bottom={0}
                      left="50%"
                      transform="translateX(-50%)"
                      size="sm"
                      background="white"
                      // colorScheme="black"
                      color="black"
                      p={3}
                      mb={3}
                      opacity="0.9"
                      mr={3}
                      fontSize="1.2rem"
                      type="button"
                      border="1px"
                      // _hover={{ bg: "white", color: "black" }}
                    >
                      {image.price}
                    </Button>
                  </a>
                  {/* 
                  <a href="https://wwws.airfrance.ro/travel-guide/destinations">
                    <Button
                      position="absolute"
                      bottom={0}
                      left="50%"
                      transform="translateX(-50%)"
                      size="sm"
                      background="white"
                      // colorScheme="black"
                      color="black"
                      p={3}
                      mb={3}
                      opacity="0.9"
                      mr={3}
                      fontSize="1.2rem"
                      type="button"
                      border="1px"
                      // _hover={{ bg: "white", color: "black" }}
                    >
                      Călătorește acum cu Air France!
                    </Button>
                  </a> */}
                </GridItem>
              </Box>
            );
          })}
        </Grid>
      </Flex>
    </>
  );
};
